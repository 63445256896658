import React from 'react';
const IMG_API = "https://image.tmdb.org/t/p/w300";

const setVoteClass = (vote) => {
  if(vote >= 8) {
    return "green";
  } else if (vote >= 6) {
    return "orange";
  } else {
    return "red";
  }
};

const Movie = ({title, poster_path, backdrop_path, overview, vote_average}) => (
  <div className="movie">
    <div className="front">
  
      <img src={(poster_path ? IMG_API + poster_path : 'https://images.unsplash.com/photo-1581905764498-f1b60bae941a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=250&q=80')} alt={title} />
    
      <div className="movie-info">
        <h3>{title}</h3>
        <span className={setVoteClass(vote_average)}>{vote_average}</span>
      </div>
    
    </div>

    <div className="back">
      <p>{(overview ? overview :
        "Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolorum dolorem quidem commodi ex ipsa facilis officia sint dignissimos ullam, non neque inventore ipsum voluptatibus provident. Praesentium reprehenderit natus quisquam earum."
         )}   
      </p>
    </div>

    <div className="background">
      <img src={(backdrop_path ? IMG_API + backdrop_path : 'https://images.unsplash.com/photo-1581905764498-f1b60bae941a?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1300&q=80')} alt={title} />
    </div>
  </div>
)


export default Movie;